<template>
    <div>
        <div v-for="index in sections.length">
            <div class="brief white-bg text-left"
                :class="index%2 == 0 ? 'white-bg': 'grey-bg'">
                <div class="container">
                    <div class="row">


                        <div class="col-xs-12 col-sm-6 col-md-6 wow fadeInLeft"
                            :class="index%2 != 0 ? 'pull-right': ''"
                            data-wow-offset="20"
                            data-wow-duration="1.75s">
                            <div class="brief-image-left">
                                <a :href="sections[getIndex(index-1)].link"><img class="img-thumbnail lazyload"
                                        :src="getImageurl(index-1)"></a>
                            </div>
                        </div>

                        <!-- BRIEF HEADING -->
                        <div class="col-xs-12 col-sm-6 col-md-6 content-section wow fadeInRight"
                            data-wow-offset="20"
                            data-wow-duration="1.75s">

                            <div class="small-text-medium uppercase colored-text"
                                vo-msg="">{{sections[getIndex(index-1)].subheader}}</div>
                            <h2 class="text-left dark-text"><a :href="sections[getIndex(index-1)].link">{{sections[getIndex(index-1)].header}}</a></h2>
                            <p v-for="p in sections[getIndex(index-1)].paras">
                                {{p}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'facility-display',
    	props: {

    	},
    	data() {
    		return {
    			sections: [{
    				header: 'Der Westflügel',
    				subheader: 'UNTERKÜNFTE IN STOLPE AUF USEDOM',
    				paras: ['Der Westflügel „Schloss Stolpe“ ist der historische Gästetrakt rechts neben dem Schweriner Herrenhaus. Hier sind moderne Ferienwohnungen (40m² bis 100m² für 2 – 8 Personen) teilweise mit Balkon, Terrasse, Küchen mit Geschirrspüler.', 'Die Maisonettes sind restaurierte ehemalige Gesindehäuser. Jede Wohnung mit eigenem Eingang und eigener Südterrasse.', 'Alle Terrassen haben direkten Zugang zur Obstwiese mit Spielfläche, Sonnenliegen und Grillmöglichkeit.', 'Bequem am Westflügel sind PKW Stellplätze. Unterstellmöglichkeiten für Fahrräder sind vorhanden.'],
    				link: './Alle.Orte/Westfluegel/WF',
    				image: 'Westflügel.jpg',
    				slider: false
    			}, {
    				header: 'Die Remise',
    				subheader: 'UNTERKÜNFTE IN STOLPE AUF USEDOM',
    				paras: ['Rechts vom „Schloss Stolpe“ liegt nach vorn versetzt die Remise. Hier findet der Gast weitere individuelle Ferienwohnungen (48m²-100m²) für bis zu 5 Personen.', 'Für den Kurzurlauber bieten sich unsere kleineren (20m²-27m²) Miniappartements im 1. OG an.', 'Ein PKW- Parkplatz steht den Gästen direkt am Gebäude zur Verfügung. Fahrradunterstellmöglichkeiten sind vorhanden.'],
    				link: './Alle.Orte/Remise/RE',
    				image: 'Remise.JPG',
    				slider: false
    			}, {
    				header: 'Der Landhof',
    				subheader: 'UNTERKÜNFTE IN STOLPE AUF USEDOM',
    				paras: ['Großzügige Ferienwohnungen (49m² -101m²) bieten die komfortable Basis für einen erholsamen Urlaub. Die Wohnungen haben teilweise Terrassen und Balkone. PKW- Stellplätze sind ausreichend vorhanden, ebenso Fahrradunterstellmöglichkeiten.', 'Auf der Rückseite des Gebäudes sind die weitläufige Liegewiese, ein Kinderspielplatz mit Trampolin, ein Fußballtor und ein Aufstellpool ( 274 x 549 x 132 cm ) in den Sommermonaten.', 'Im Landhof findet der Gast unsere Rezeption.'],
    				link: './Alle.Orte/Landhof/LH',
    				image: 'Landhof.jpg',
    				slider: false
    			}, {
    				header: 'Das Landhaus am Haff',
    				subheader: 'UNTERKÜNFTE IN STOLPE AUF USEDOM',
    				paras: ['Die freundliche Ferienwohnanlage „Landhaus am Haff“ liegt ca. 500m vom Ortsmittelpunkt Stolpe und dem Schloss entfernt. Die beiden idyllischen reetgedeckten Häuser mit den Ferienwohnungen wurden auf einem 5000m² großen Grundstück gebaut. Fast alle Ferienwohnungen sind modernisiert und verfügen über Terrasse oder Balkon. Die Wohnungen haben eigene PKW- Stellplätze. Unterstellmöglichkeiten für Fahrräder sind vorhanden.', 'Terrassen mit Strandkörben, eine weitläufige Liegewiese, ein großer Abenteuer-Kinderspielplatz und Grillplätze stehen den Gästen zur Verfügung.'],
    				link: './Alle.Orte/Landhaus/LHH',
    				image: 'Landhaus-Uebersicht-min.jpg',
    				slider: false
    			}, {
    				header: 'Die Haffhäuser',
    				subheader: 'UNTERKÜNFTE IN ZIRCHOW AUF USEDOM',
    				paras: ['Ab sofort wird das IHRE WOHLFÜHLURLAUBSADRESSE sein!', 'Wie Ihnen garantiert bereits bekannt ist, ist Usedom ein Urlaubsparadies für Ostseeliebhaber. Egal zu welcher Jahreszeit Sie diese wunderbare Insel genießen werden, eins steht fest: Am Stettiner Haff, in Ihren Haffhäuser-Ferienwohnungen, finden Sie immer Ihren optimalen Ausgleich zwischen Aktiv-, Erlebnis- oder Erholungsurlaub.', 'Das Haff im direkten Blick, die grüne Landschaft direkt vor der Haustür und die Erlebnisse direkt vor Ort.'],
    				link: './Alle.Orte/Haffhaeuser/HH',
    				image: 'Aussenanlage_Haffhaeuser.jpg',
    				slider: false
    			}]
    		}
    	},
    	methods: {
    		getIndex: function(lastIndex) {
    			var now = new Date();
    			var start = new Date(now.getFullYear(), 0, 0);
    			var diff = now - start;
    			var oneDay = 1000 * 60 * 60 * 24;
    			var day = Math.floor(diff / oneDay);
    			var index = lastIndex + (day % this.sections.length);
    			if (index >= this.sections.length) {
    				index = (index - this.sections.length);
    			}
    			/*console.log('Day of year: ' + day);
    			console.log('received index:' + lastIndex);
    			console.log('Returned index::' + index);*/
    			return index;
    		},
    		getImageurl: function(lastIndex) {
    			var index = this.getIndex(lastIndex);
    			return 'https://r.v-office.com/v860/1653463521000/img/' + this.sections[index].image;
    		}
    	}

    };
</script>