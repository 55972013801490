var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.sections.length, function(index) {
      return _c("div", [
        _c(
          "div",
          {
            staticClass: "brief white-bg text-left",
            class: index % 2 == 0 ? "white-bg" : "grey-bg"
          },
          [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  {
                    staticClass: "col-xs-12 col-sm-6 col-md-6 wow fadeInLeft",
                    class: index % 2 != 0 ? "pull-right" : "",
                    attrs: {
                      "data-wow-offset": "20",
                      "data-wow-duration": "1.75s"
                    }
                  },
                  [
                    _c("div", { staticClass: "brief-image-left" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.sections[_vm.getIndex(index - 1)].link
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "img-thumbnail lazyload",
                            attrs: { src: _vm.getImageurl(index - 1) }
                          })
                        ]
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-6 col-md-6 content-section wow fadeInRight",
                    attrs: {
                      "data-wow-offset": "20",
                      "data-wow-duration": "1.75s"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "small-text-medium uppercase colored-text",
                        attrs: { "vo-msg": "" }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.sections[_vm.getIndex(index - 1)].subheader
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c("h2", { staticClass: "text-left dark-text" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.sections[_vm.getIndex(index - 1)].link
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.sections[_vm.getIndex(index - 1)].header)
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(
                      _vm.sections[_vm.getIndex(index - 1)].paras,
                      function(p) {
                        return _c("p", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(p) +
                              "\n                        "
                          )
                        ])
                      }
                    )
                  ],
                  2
                )
              ])
            ])
          ]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }